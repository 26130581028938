.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.about h1 {
    font-size: 2.5rem;
    font-family: 'Open Sans', sans-serif;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 70px;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.content img {
    height: 20em;
    margin: 30px;
    border-radius: 10px;
}

.content p {
    text-align: justify;
}

.container{
    width: 40%;
    font-size: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.proj-icons {
    display: flex;
    flex-direction: column;
}

.proj-icon {
    font-size: 3rem;
    margin: 1rem;
    transition: all 0.3s;
}

.fa-inverse:hover {
    color: #CC5500;
    cursor: pointer;
}

#resume-button {
    display: none;
}

@media screen and (max-width: 767px) {
    .about {
        height: auto;
    }
    .content img {
        margin: 0;
    }

    .container {
        width: 80%;
    }

    .proj-icons {
        flex-direction: row;
    }
}







:root {
  --prime: #CC5500;
}


body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #0a192f;
  color: seashell;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


span {
  color: var(--prime);
}

.contact {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: Center;
    flex-direction: column;
}

.contact h1 {
    font-size: 2.5rem;
}

form {
    background-color: #071121;
    display: flex;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    margin-top: 80px;
}

.email-icon {
    font-size: 2rem;
}

.form-email {
    display: flex;
    flex-direction: row;
}

#form-title {
    font-weight: 800;
    margin-bottom: 30px;
}

input, form textarea {
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background: #0b1c36;
    font-size: 16px;
    color: #fff;
    resize: none;
}

form button {
    padding: 15px;
    background: #CC5500;
    color: white;
    font-size: 18px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0;
    border-radius: 30px;
}

form h3 {
    font-weight: 500;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder{
  font-family: 'myFont', Arial, Helvetica, sans-serif;
}

:root {
    --prime: #CC5500;
  }

.navbar {
    background-image: linear-gradient(to right, #112240 , #17366b);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: seashell;
    height: 60px;
    position: fixed;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    z-index: 2;
}

.links {
    display: flex;
    flex-direction: row;
    margin-right: 50px;
}

.link-wrapper {
    margin: 0 10px;
}

#logo {
    color: var(--prime);
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 60px;
}

#link {
    position: relative;
    width:100%;
    display: block;
    text-align: center;
    transition: color 0.4s ease;
}

#link:hover {
    cursor: pointer;
    color: var(--prime);
    display: flex;
    align-items: center;
}

#link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    top: 0;
    background-color: var(--prime);
    transition: width 0.2s ease-in;
  }

#link:hover::before,
#link:focus::before {
      width: 100%;
}

@media screen and (max-width: 767px) {
    #logo {
        margin-left: 10px;
    }

    .links {
        display: none;
    }
}

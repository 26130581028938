.projects {
    color: seashell;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.proj-arrow-left {
    position: absolute;
    left: 100px;
    top: 40%;
    height: 150px;
}

.proj-arrow-right {
    position: absolute;
    right: 100px;
    top: 40%;
    height: 150px;
}

.proj-arrow-left:hover {
    cursor: pointer;
}

.proj-arrow-right:hover {
    cursor: pointer;
}


.projects h1 {
    text-align: left;
    font-size: 2.5rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 70px;
}

.slider {
    position: absolute;
    display: flex;
    height: 90%;
    left: 0;
    top: 10%;
    transition: all 1s ease-in-out;
}

.item-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    width: 50%;
    height: 60%;
    background-color: #071121;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 10px;
} 

.item h2 {
    font-size: 30px;
}

.item .left {
    flex: 4;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 15px;
}

.left h2 {
    text-align: center;
}

.stack-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.stack-icon {
    font-size: 3rem;
    margin: 1rem;
    transition: all 0.2s;
}

.fa-inverse:hover {
    color: #CC5500;
    cursor: pointer;
}

.item .right {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right img {
    width: 85%;
    margin: 10px;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
    .projects {
        overflow: visible;
        height: auto;
    }

    .proj-arrow-left {
        display: none;
    }

    .proj-arrow-right {
        display: none;
    }

   .slider {
       position: static;
       flex-direction: column;
       height: auto;
   }
    .item-container {
        margin-bottom: 50px;
    }

    .item {
        width: 80%;
        height: 100%;
        flex-direction: column-reverse;
    }

    .item .left {
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        padding: 20px;
    }

    .item .right {
        display: flex;
        align-items: center;
        justify-content: center;
    }
} 

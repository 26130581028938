.landing {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5rem;
    position: relative;
}

.wrapper {
    color: seashell;
    font-size: 1.2em;
}

.contact-button {
    background-color: transparent;
    color: seashell;
    border-color: var(--prime);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.5s;
}

.contact-button:hover {
    background-color: var(--prime);
    cursor: pointer;
}

.arrow {
    position: absolute;
    font-size: 3rem;
    animation: arrow-blink 2s infinite;
    bottom: 0;
}

.ityped-cursor {
    font-size: 2.2rem;
    opacity: 1;
    -webkit-animation: blink 0.6s infinite;
    -moz-animation: blink 0.6s infinite;
    animation: blink 0.6s infinite;
    animation-direction: alternate;
}
 
@keyframes blink {
    100% {
        opacity: 0;
    }
}

@keyframes arrow-blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
 
@-webkit-keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-moz-keyframes blink {
    100% {
        opacity: 0;
    }
}